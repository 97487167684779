function toggleQuestionForm() {
    var messenger = $(".messenger");
    var destination = "-600px";
    var margin = "5px";

    var width = $(document).width();

    if (width < 719) {
        destination = "-420px";
        margin = "5px"
    } else if (width < 1023) {
        destination = "-600px";
    } else if (width < 1439) {
        destination = "-570px";
    }

    if (messenger.css("margin-top") != destination) {
        messenger.stop().animate({
            marginTop: destination
        }, 500, "linear");
    } else {
        messenger.stop().animate({
            marginTop: margin
        }, 500, "linear");
    }
}

$(document).ready(function () {
    $(document).on("click", ".responsive-nav-button", function () {
        $(".header nav").toggle();
    });

    $(document).on("click", "[data-trigger='question-form']", function () {
        toggleQuestionForm();
    });

    $(".slider").owlCarousel({
        items: 1,
        autoplay: true,
        nav: false, // Show next and prev buttons
        autoplaySpeed: 300,
        dotsSpeed: 400,
        loop: true,
        margin: 1
    });

    $(".contact-form-status").delay(4000).hide(1000);

    var allPanels = $("#faq > .faq-answer").hide();


    $("#faq > ul > li > a").on('click', function (e) {
        if ($(this).parent().hasClass("is-expanded")) {
            $(this).parent().find('p').slideUp();
            $(this).parent().removeClass('is-expanded');
        } else {
            $(this).parent().find('p').slideDown(500);
            $(this).parent().next().slideDown();
            $(this).parent().toggleClass('is-expanded');
        }
        e.preventDefault();
    });

});