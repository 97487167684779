
/* Cookie-Banner Script */
/*
*  Cookie Banner by epunks GmbH
*
*  trainee for software engineering Martin Glück
*
*/

/** Set Cookie
 *
 * @param cname
 * @param cvalue
 * @param exdays
 */
function setCookie( cname, cvalue, exdays )
{
    var d           = new Date();
    d.setDate(d.getDate() + (exdays));
    var expires     = "expires=" + d.toUTCString();
    document.cookie = cname +"=" + cvalue + ";"+ expires + ";path=/" ;
}

/** Remove Cookie
 *
 * @param cname
 */
function removeCookie (cname) {
    document.cookie = cname + "=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT"
}

/** Get actual Cookie
 *
 * @param cname
 * @returns {*}
 */
function returnCookie ( cname )
{
    var name       = cname + "=";
    var ca = document.cookie.split(';');
    for(var i      = 0; i < ca.length; i++)
    {
        var c      = ca[i];
        while (c.charAt(0) == ' ')
        {
            c      = c.substring(1);
        }
        if (c.indexOf(name) == 0)
        {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


/** Hide div on click
 *
 */
function rmdiv()
{
    if(document.getElementById("cookie-banner")) {
        document.getElementById("cookie-banner").setAttribute('style', 'display:none');
    }
}

/** Check if action cookie exists, otherwise displaydiv() and initEventlistener() are triggered
 *
 */
function checkCookie()
{
    var cookie_accept       = returnCookie("cookie-accept");
    var cookie_decline      = returnCookie("cookie-decline");
    var cookie_maps_accept  = returnCookie("cookie-maps-accept");
    var cookie_maps_decline = returnCookie("cookie-maps-decline");
    if (cookie_accept || cookie_decline)
    {
        rmdiv();
        var a       = document.createTextNode("");
        document.body.appendChild(a);
        // manipulate tags
        manipulateScriptTag();
    } else {
        document.getElementById("cookie-banner").setAttribute('style', 'display:block');
        initEventListener();
        // manipulate tags
        manipulateScriptTag();
    }

    if (cookie_maps_accept || cookie_maps_decline)
    {
        // manipulate tags
        manipulateScriptTagGoogleMaps();
    } else {
        // manipulate tags
        manipulateScriptTagGoogleMaps();
    }
}

/**
 * Switch to Decline Action in privacy policy
 */
function switchToDecline(){
    setCookieDecline();
    declineManipulateStripTags();
}

/**
 * Switch to Accept Action in privacy policy
 */
function switchToAccept(){
    setCookieAccept();
    manipulateScriptTag();
}

/**
 * Eventlistener, onclick function: set Cookie, hide the Consent, reload page with setted cookie
 */
function initEventListener ()
{
    document.getElementById('btn-accept').addEventListener("click", function()
    {
        setCookieAccept();
        rmdiv();
        manipulateScriptTag();
        window.location.reload(true);
    });
    document.getElementById('btn-reject').addEventListener("click", function()
    {
        setCookieDecline();
        rmdiv();
        declineManipulateStripTags();
        window.location.reload(true);
    });
    if(document.getElementById('btn-maps-accept')) {
        document.getElementById('btn-maps-accept').addEventListener("click", function () {
            setGoogleMapsCookieAccept();
            manipulateScriptTagGoogleMaps();
            window.location.reload(true);
        });
    }

}

function initRevertOptions(){

    var a_decline           = document.createElement("A");
    var a_accept            = document.createElement("A");
    var p_info              = document.createElement("P");
    var a_decline_text      = document.createTextNode("Cookies ablehnen");
    var a_accept_text       = document.createTextNode("Cookies akzeptieren");

    a_decline.className     = "revert-a-tag";
    p_info.className        = "info_p";
    a_accept.className      = "revert-a-tag";
    a_decline.id            = "decline-revert-option";
    a_accept.id             = "accept-revert-option";
    a_decline.href          = "#";
    a_accept.href           = "#";


    if (returnCookie("cookie-accept")){
        var p_info_text         = document.createTextNode("Sie haben zur Zeit die Cookies aktiviert");
    } else{
        var p_info_text         = document.createTextNode("Sie haben zur Zeit die Cookies deaktiviert");
    }

    p_info.appendChild(p_info_text);
    a_accept.appendChild(a_accept_text);
    a_decline.appendChild(a_decline_text);

    if(document.getElementById("replace-cookie-revert") !== null) {

        document.getElementById("replace-cookie-revert").appendChild(p_info);
        document.getElementById("replace-cookie-revert").appendChild(a_accept);
        document.getElementById("replace-cookie-revert").appendChild(a_decline);

        //Check for toggle Cookies
        if (returnCookie("cookie-decline")) {
            document.getElementById("decline-revert-option").setAttribute('style', 'display:none');
        }
        if (returnCookie("cookie-accept")) {
            document.getElementById("accept-revert-option").setAttribute('style', 'display:none');
        }


        document.getElementById("decline-revert-option").addEventListener("click", function () {
            setCookieDecline();
            declineManipulateStripTags();
            window.location.reload(true);
        });

        document.getElementById("accept-revert-option").addEventListener("click", function () {
            setCookieAccept();
            manipulateScriptTag();
            window.location.reload(true);
        });
    }
}

/**
 * set Cookie for accepting cookies
 */
function setCookieAccept() {
    if (returnCookie("cookie-decline")) {
        removeCookie("cookie-decline");
    }
    setCookie('cookie-accept', true, 32850);
}

/**
 * set Cookie for not accepting cookies
 */
function setCookieDecline() {
    if (returnCookie("cookie-accept")) {
        removeCookie("cookie-accept");
    }
    setCookie('cookie-decline', true, 32850);
}


/**
 * remove Element
 */
function removeElement(elemtId){

    var element = document.getElementById(elemtId);
    element.parentNode.removeChild(element);
}

/**
 *  manipulate script and iframe tags
 */
function manipulateScriptTag() {
    if (returnCookie("cookie-accept")) {
        // check all script-tags and find if they have the cookie-consent attribute
        var scripts = document.getElementsByTagName("script");
        for (var i = 0; i < scripts.length; ++i) {
            if( scripts[i].getAttribute('data-cookieconsent') !== "undefined" && scripts[i].getAttribute('data-cookieconsent') !== null ){
                var ga_script = scripts[i].innerHTML;
                scripts[i].id   = "delete-script";
                var new_script  = document.createElement("SCRIPT");
                var new_script_text = document.createTextNode(ga_script);

                new_script.setAttribute("data-cookieconsent-accepted", "statistics");
                new_script.setAttribute("type", "text/javascript");


                new_script.appendChild(new_script_text);
                document.head.appendChild(new_script);
                removeElement("delete-script");
            }
        }
        // check all iframe-tags and find if they have the cookie-consent attribute
        var iframes = document.getElementsByTagName("iframe");
        for (var i = 0; i < iframes.length; ++i) {
            if( iframes[i].getAttribute('data-cookieconsent') !== "undefined" && iframes[i].getAttribute('data-cookieconsent') !== null ){
                var data_src = iframes[i].getAttribute("data-src");
                iframes[i].setAttribute("data-src", "");
                iframes[i].setAttribute("src", data_src);
            }
        }
    }
}

/**
 *  manipulate script and iframe tags
 */
function declineManipulateStripTags() {
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; ++i) {
        if( scripts[i].getAttribute('data-cookieconsent-accepted') !== "undefined" && scripts[i].getAttribute('data-cookieconsent-accepted') !== null){
            scripts[i].setAttribute("type", "text/plain");
            var cookieconsent_val = scripts[i].getAttribute("data-cookieconsent-accepted");
            scripts[i].setAttribute("data-cookieconsent", cookieconsent_val );
            console.log(scripts);
        }
    }
    // check all iframe-tags and find if they have the cookie-consent attribute
    var iframes = document.getElementsByTagName("iframe");
    for (var i = 0; i < iframes.length; ++i) {
        if( iframes[i].getAttribute('data-cookieconsent') !== "undefined" && iframes[i].getAttribute('data-cookieconsent') !== null){
            var src = iframes[i].getAttribute("src");
            iframes[i].setAttribute("src", "");
            iframes[i].setAttribute("data-src", src);
        }
    }
}

/**
 * manipulate google maps iframes
 */
function manipulateScriptTagGoogleMaps(){
    if(returnCookie("cookie-maps-accept") != "") {
        var mapsFrames = document.getElementsByTagName('iframe');
        for (var i = 0; i < mapsFrames.length; ++i){
            if( mapsFrames[i].getAttribute('data-cookiemaps') !== "undefined" && mapsFrames[i].getAttribute('data-cookiemaps') !== null){
                var src = mapsFrames[i].getAttribute("data-src");
                mapsFrames[i].setAttribute("data-src", "");
                mapsFrames[i].setAttribute("src", src);
            }
        }
    }
}

/**
 * decline google maps iframes
 */
function declineManipulateScriptTagGoogleMaps(){
    var mapsFrames = document.getElementsByTagName('iframe');
    for (var i = 0; i < mapsFrames.length; ++i){
        if( mapsFrames[i].getAttribute('data-cookieconsent') !== "undefined" && mapsFrames[i].getAttribute('data-cookieconsent') !== null){
            var src = mapsFrames[i].getAttribute("src");
            mapsFrames[i].setAttribute("src", "");
            mapsFrames[i].setAttribute("data-src", src);
        }
    }
}

/**
 * init revert options for privacy
 */
function initMapsRevertOptions() {
    var a_decline_maps           = document.createElement("A");
    var a_accept_maps            = document.createElement("A");
    var p_info_maps              = document.createElement("P");
    var a_decline_maps_text      = document.createTextNode("Google Maps ablehnen");
    var a_accept_maps_text       = document.createTextNode("Google Maps akzeptieren");

    a_decline_maps.className     = "revert-a-tag";
    p_info_maps.className        = "info_p";
    a_accept_maps.className      = "revert-a-tag";
    a_decline_maps.id            = "decline-revert-maps-option";
    a_accept_maps.id             = "accept-revert-maps-option";
    a_decline_maps.href          = "#";
    a_accept_maps.href           = "#";

    if (returnCookie("cookie-maps-accept")){
        var p_info_maps_text         = document.createTextNode("Sie haben zur Zeit Google Maps aktiviert");
    } else{
        var p_info_maps_text         = document.createTextNode("Sie haben zur Zeit Google Maps deaktiviert");
    }

    p_info_maps.appendChild(p_info_maps_text);
    a_accept_maps.appendChild(a_accept_maps_text);
    a_decline_maps.appendChild(a_decline_maps_text);

    if(document.getElementById("replace-cookie-maps-revert") !== null) {

        document.getElementById("replace-cookie-maps-revert").appendChild(p_info_maps);
        document.getElementById("replace-cookie-maps-revert").appendChild(a_accept_maps);
        document.getElementById("replace-cookie-maps-revert").appendChild(a_decline_maps);

        //Check for toggle Cookies
        if (returnCookie("cookie-maps-decline")) {
            document.getElementById("decline-revert-maps-option").setAttribute('style', 'display:none');
        }
        if (returnCookie("cookie-maps-accept")) {
            document.getElementById("accept-revert-maps-option").setAttribute('style', 'display:none');
        }


        document.getElementById("decline-revert-maps-option").addEventListener("click", function () {
            setGoogleMapsCookieDecline();
            declineManipulateScriptTagGoogleMaps();
            window.location.reload(true);
        });

        document.getElementById("accept-revert-maps-option").addEventListener("click", function () {
            setGoogleMapsCookieAccept();
            manipulateScriptTagGoogleMaps();
            window.location.reload(true);
        });
    }
}

/**
 * Set cookie for declining google maps
 */
function setGoogleMapsCookieDecline() {
    if (returnCookie("cookie-maps-accept")) {
        removeCookie("cookie-maps-accept");
    }
    setCookie('cookie-maps-decline', true, 32850);
}

/**
 * set cookie for accepting google maps
 */
function setGoogleMapsCookieAccept() {
    if (returnCookie("cookie-maps-decline")) {
        removeCookie("cookie-maps-decline");
    }
    setCookie('cookie-maps-accept', true, 32850);
}

window.onload = function (){
    checkCookie();initRevertOptions();initMapsRevertOptions();
};
